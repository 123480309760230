<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="cancel" :open="open">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
						
						<form @submit.prevent="submitForm">
							<div class="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-6">
								<div class="sm:flex sm:items-start">
									<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
										<StarIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
									</div>
									<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left py-2">
										<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
											Asigna puntos
										</DialogTitle>
										<div class="mt-2 text-sm text-gray-500">
											<div>
												<span class="text-gray-400 mr-1">Caso:</span> 
												<span class="text-black">{{ state.model.title }}</span>	
											</div>
											<div class="mt-1">
												<span class="text-gray-400 mr-1">Residente:</span> 
												<span class="text-black">{{ state.model.name }}</span>	
											</div>

											<div class="mt-3">
												<div class="mb-1 text-xs text-gray-400">Puntos:</div>
												<input v-model="state.model.points" type="number" autocomplete="off" :disabled="state.sending" placeholder="Puntos" class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-accent-500 focus:border-accent focus:z-10 sm:text-sm" />
											</div>
											<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
												<div v-if="v$.model.points.$error">{{v$.model.points.$errors[0].$message}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button type="submit" v-if="!state.sending" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-400 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
									Guardar
								</button>
								<button type="button" v-if="!state.sending" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancel" ref="cancelButtonRef">
									Cancelar
								</button>

								<div v-if="state.sending" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-400 bg-gray-200">
									Espera un momento...
								</div>
							</div>
						</form>

					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { StarIcon } from '@heroicons/vue/outline'
import Cases from '@/firebase/cases'

export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		StarIcon,
	},
	props: ['caso'],
	setup(props, {emit}) {
		const open = ref(true);

		const store = useStore();

		const state = reactive({
			model: props.caso,
			sending: false
		})

		function cancel() {
			emit('cancel');
		}

		function proceed () {
			emit('proceed');
		}

		const rules = computed(() => {
				return {
					model: {
						points: { 
							required: helpers.withMessage('Puntos no válidos. Puedes poner 0 pts.', required),
							maxLength: helpers.withMessage('No más de 10 mil puntos por favor, no se lo merece.', maxLength(5)),
						}
					}
				}
			});
		
		const v$ = useValidate(rules, state);

		function submitForm() {
			v$.value.$validate()

			if (!v$.value.$error) {
				state.sending = true;

				state.model.points = Number(state.model.points)

				Cases.assignPoints(state.model)
					.then(response => {
						if (response == true) {
							emit('maded');
							store.dispatch('setNotification', {type: true, title: 'Puntos modificados', content: 'Se han modificado correctamente.'});
						} else {
							store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión.'});
						}
						state.sending = false;
					});
			}
		}

		return {
			open,
			cancel,
			proceed,
			state,
			submitForm,
			v$
		}
	},
}
</script>