<template>
	<div class="text-primary text-xl lg:text-2xl mt-8 mb-6">
		Casos clínicos <span class="text-sm" v-if="state.loaded">({{state.total}})</span> 
	</div>
		
	<div class="flex flex-col" v-if="state.loaded">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Residente
								</th>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Título del caso
								</th>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Puntos asignados
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">Asignar puntos</span>
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">Descargar</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in state.cases" :key="item.id" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
								<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
									{{ item.name }}
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
									<p class="truncate overflow-hidden w-72">{{ item.title }}</p>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
									{{ item.points }}
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<span class="text-primary hover:text-primary cursor-pointer" @click="openDialog(item)">Asignar puntos</span>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<a :href="item.url" target="_blank" class="text-accent hover:text-accent">Descargar</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<DialogPoints 
		v-if="state.showDialog"
		:caso="state.actualCase"
		@cancel="state.showDialog=false"
		@maded="reInit" />
</template>

<script>
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { MenuIcon, PaperClipIcon, BanIcon, XIcon, CheckIcon } from '@heroicons/vue/outline'
import { ChevronLeftIcon, FilterIcon, MailIcon, PhoneIcon, SearchIcon, ClockIcon, CollectionIcon, HandIcon } from '@heroicons/vue/solid'
import Cases from '@/firebase/cases'
import Helpers from '@/services/helpers'
import Misc from '@/services/misc'
import _ from 'lodash'
import DialogPoints from '@/components/cases/DialogPoints'

export default {
	components: {
		DialogPoints
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		const user = computed(() => store.getters.user)

		const state = reactive({
			cases: [],
			sending: false, 
			total: 0,
			loaded: false,
			showDialog: false,
			actualCase: null
		});

		onMounted(() => {
			if (user.value.role != 'staff') {
				router.push('/')
			} else {
				init()
			};
		});

		function reInit() {
			state.actualCase = null;
			state.showDialog = false;
			init();
		}

		function init() {
			Cases.getAll()
				.then(query => {
					if (query) {
						state.cases = Helpers.formatItems(query);
						state.total = state.cases.length;
						state.loaded = true;

						// state.cases = _.orderBy('points', 'asc');
					} else {
						store.dispatch('setNotification', {type: false, content: 'Error de comunicación.'});
					}
				});
		}

		function openDialog(cas) {
			state.actualCase = cas;
			state.showDialog = true;
		}

		return {
			state,
			openDialog,
			reInit
		}
	},
}
</script>